.App {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.Timers {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.Metronome {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.CircleFifths {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.Keys {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

#showAbout {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  height: 45px;
  width: 45px;
  justify-self: end;
}

.horizontal-slider {
  width: 50%;
  max-width: 500px;
  height: 20px;
  margin: auto;
}
.slider-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.slider-thumb.active {
  background-color: grey;
}
.slider-track {
  position: relative;
  background: #ddd;
}
.slider-track.slider-track-0 {
  background: #83a9ff;
}
.horizontal-slider .slider-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .slider-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}